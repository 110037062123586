.ProjectCard {
  width: 100%;
  max-width: 300px;
  @media only screen and (min-width: 700px) {
    max-width: 350px;
  }

  margin: 10px;
  margin-bottom: 20px;

  .aspectRatioContainer {    
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 60% 0 0 0; /* 100 / (w / h) => 100 / (10 / 6) => 60% */

    cursor: pointer;
  
    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .cardBody {
    background-color: var(--text-lite);
    
    .cardTitle {
      color: var(--string);
      font-size: calc(10px + 2.2vmin);
    }
    .cardText {
      color: var(--background-lite);
      font-size: calc(8px + 1.1vmin);
    }
    .showMore {
      padding-left: 1em;
      font-size: 0.8em;

      color: var(--function-lite);
      text-transform: uppercase;
      cursor: pointer;

      display: inline-block;
    }
    .details {
      display: none;
    }
    .showDetails {
      display: block;
    }
    .technologies {
      display: block;
      margin-top: 1em;
    }

    button {
      margin-right: 5px;
      background-color: var(--string);
      border-color: var(--string-lite);
      color: var(--background-lite);
      font-size: calc(8px + 1vmin);
      padding: 5px;
    }
  }
  
}
