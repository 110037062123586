.skillContainer {
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    margin-left: 10px;
    letter-spacing: 0.05em;

    color: var(--function);
    span {
      color: var(--symbol);
    }
  }

  .SkillList {
    
    .SkillCategory {
      padding: 10px;
      padding-left: 50px;

      .categoryHeader {
        margin-left: -20px;
        margin-top: 0;
        color: var(--name-lite)
      }

      .skillElements {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
  
    }

  }
}