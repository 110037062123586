.projectContainer {
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    margin-left: 10px;
    color: var(--function);
    span {
      color: var(--symbol);
    }
  }
  
  .ProjectList {
    padding: 10px;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;  
  }

}
