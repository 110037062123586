/* 
  CSS Scrolling text in Star Wars style is sourced from Craig Buckler:
  http://www.sitepoint.com/css3-starwars-scrolling-text/
*/

@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);

.relativeContainer {
  position: relative;
}

.ScrollingBio {
  /* this height drives some values below */
  height: 300px;

  overflow: hidden;

  background: linear-gradient(
    to bottom,
    var(--background), 
    black 10%, 
    black 90%, 
    var(--background)
  );

  font-family: "Droid Sans", arial, verdana, sans-serif;
  font-weight: 700;
  color: #ff6;

  cursor: default;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 

  .preEpisode {
    position: absolute;
    /* bottom here should be -80% of ScrollingBio height */
    bottom: -260px;
    z-index: 1;
  }

  .episodeLeadin
  {
    left: 10%;
    right: 10%;

    color: #4ee;
    font-size: calc(20px + 2vmin);
    line-height: 1.1em;
    font-weight: 400;

    opacity: 0;
    -webkit-animation: intro 3s ease-out;
    -moz-animation: intro 3s ease-out;
    -ms-animation: intro 3s ease-out;
    -o-animation: intro 3s ease-out;
    animation: intro 3s ease-out;
  }

  .episodeTitle {
    left: 50%;
    width: 2.6em;
    margin-left: -1.3em;
    text-align: center;

    font-size: 8em;
    line-height: 0.8em;
    letter-spacing: -0.05em;
    color: #000;
    text-shadow: 
      -2px -2px 0 #ff6, 
      2px -2px 0 #ff6, 
      -2px 2px 0 #ff6, 
      2px 2px 0 #ff6;
    
    opacity: 0;
    -webkit-animation: logo 5s ease-out 3.5s;
    -moz-animation: logo 5s ease-out 3.5s;
    -ms-animation: logo 5s ease-out 3.5s;
    -o-animation: logo 5s ease-out 3.5s;
    animation: logo 5s ease-out 3.5s;
  }

  /* the interesting 3D scrolling stuff */
  #titles
  {
    position: absolute;

    /* this height should be 150% the height of ScrollingBio */
    /* top should be set to 60% of ScrollingBio */
    height: 450px;
    top: -180px;
    left: calc(5px + 10vw);
    right: calc(5px + 10vw);

    overflow: hidden;

    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: perspective(300px) rotateX(30deg);
    -moz-transform: perspective(300px) rotateX(30deg);
    -ms-transform: perspective(300px) rotateX(30deg);
    -o-transform: perspective(300px) rotateX(30deg);
    transform: perspective(300px) rotateX(30deg);
  }

  #titles p {
    font-size: calc(1em + 1vw);
    text-align: justify;
    margin: 0.8em 0;

    &.center {
      text-align: center;
    }

    s { 
      position: relative 
    }
    s::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-bottom: 0.225em solid #ff6;
      margin-top: calc(0.225em / 2 * -1);
    }
  }

  #titlecontent {
    position: absolute;
    top: 200%;
    -webkit-animation: scroll 60s linear 5.5s infinite;
    -moz-animation: scroll 60s linear 5.5s infinite;
    -ms-animation: scroll 60s linear 5.5s infinite;
    -o-animation: scroll 60s linear 5.5s infinite;
    animation: scroll 60s linear 5.5s infinite;
  }

  #titles:after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 40%;
    background-image: -webkit-linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 100%);
    background-image: -moz-linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 100%);
    background-image: -ms-linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 100%);
    background-image: -o-linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 100%);
    background-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 100%);
    pointer-events: none;
  }


  /* ANIMATIONS */
  /* Scroll animation - note: start point should be 150% the height of ScrollingBio */
  @-webkit-keyframes scroll {
    0% { top: 450px; }
    100% { top: -200vh; }
  }
  @-moz-keyframes scroll {
    0% { top: 450px; }
    100% { top: -200vh; }
  }
  @-ms-keyframes scroll {
    0% { top: 450px; }
    100% { top: -200vh; }
  }
  @-o-keyframes scroll {
    0% { top: 450px; }
    100% { top: -200vh; }
  }
  @keyframes scroll {
    0% { top: 450px; }
    100% { top: -200vh; }
  }

  /* Fade in animation for intro */
  @-webkit-keyframes intro {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  @-moz-keyframes intro {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  @-ms-keyframes intro {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  @-o-keyframes intro {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  @keyframes intro {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }

  /* shrink and fade to black animation for logo */
  @-webkit-keyframes logo {
    0% { -webkit-transform: scale(1); opacity: 1; }
    50% { opacity: 1; }
    100% { -webkit-transform: scale(0.1); opacity: 0; }
  }
  @-moz-keyframes logo {
    0% { -moz-transform: scale(1); opacity: 1; }
    50% { opacity: 1; }
    100% { -moz-transform: scale(0.1); opacity: 0; }
  }
  @-ms-keyframes logo {
    0% { -ms-transform: scale(1); opacity: 1; }
    50% { opacity: 1; }
    100% { -ms-transform: scale(0.1); opacity: 0; }
  }
  @-o-keyframes logo {
    0% { -o-transform: scale(1); opacity: 1; }
    50% { opacity: 1; }
    100% { -o-transform: scale(0.1); opacity: 0; }
  }
  @keyframes logo {
    0% { transform: scale(1); opacity: 1; }
    50% { opacity: 1; }
    100% { transform: scale(0.1); opacity: 0; }
  }

}