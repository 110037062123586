.Bio {
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    margin-left: 10px;
    color: var(--function);
    span {
      color: var(--symbol);
    }
  }

  .controls {
    cursor: pointer;

    &:hover {
      img {
        box-shadow: 
          inset 0 0 5px 3px var(--text),
          0 0 10px var(--text);
      }
      figcaption {
        text-shadow: 0.5px -0.5px var(--function);
      }
    }

    img {
      display: inline-block;
      margin-left: 20px;
      margin-right: 10px;

      object-fit: cover;
      height: 1em;
      width: 1em;

      border-radius: 50%;
      background-color: var(--text-lite-liter);
    }

    /* How to make a foreground image into a sprite comes from css-tricks: 
    https://css-tricks.com/spriting-img/ */
    .playSprite {
      object-position: 0.04em 0;
    }
    .pauseSprite {
      object-position: -1.06em 0;
    }
    
    figcaption {
      display: inline-block;
      font-size: 0.6em;
      text-transform: uppercase;
      color: var(--function-lite);
    }

  }

}
