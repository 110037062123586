.contactContainer {
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 30px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  h3 {
    display: inline;
    margin: 0;

    color: var(--function);
    span {
      color: var(--symbol);
    }
  }

  .contactList {
    display: inline-flex;
    flex-wrap: nowrap;    
  }
}
