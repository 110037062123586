.ContactForm {
  position: fixed;
  z-index: 1;
  
  height: 100vh;
  width: 100vw;
  
  background-color: var(--text);
  
  .formArea {
    z-index: 2;
    margin-top: 50vh;
    transform: translate(0, -50%);

    min-height: 50vh;
    padding: 5px;
    padding-top: 5vh;
    padding-bottom: 5vh;

    color: var(--variable);
    background-color: var(--background);
    border-radius: 10px;

    h3 {
      margin-bottom: .8em;
    }

    textarea {
      height: 4em;
      min-height: 2em;
      max-height: 50vh;
      
      @media only screen and (min-width: 700px) {
        height: 8em;
      }  
    }

    label {
      color: var(--css-class-lite)
    }

    .bumpUpward {
      margin-top: -0.5em;
      margin-bottom: 0.5em;
    }
  }

  .contactSubmitButton {
    color: var(--background);
    background-color: var(--variable);
    border-color: var(--variable);

    @media only screen and (min-width: 700px) {
      color: var(--background);
      background-color: var(--variable-lite);
      border-color: var(--variable-lite);
      
      &:hover {
        color: var(--background);
        background-color: var(--variable);
        border-color: var(--variable);
      }
    }
  }
}

.hidden {
  display: none;
}