.Mission {
  padding-top: 15px;
  padding-bottom: 20px;

  h3 {
    position: relative;

    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--function);
    height: 1em;

    .cursor {
      width: 0;
      height: 100%;
      border-right: .1em solid transparent;
      -webkit-animation: blink ease 1s infinite;
      animation: blink ease 1s infinite;
    }

    .predictive {
      position: absolute;
      top: 2em;

      display: inline-block;
      width: 10rem;
      font-size: 0.6em;
      
      overflow-x: visible;
      white-space: nowrap;
      
      color: white;
      border: 1px solid var(--text-lite);
      background-color: var(--background-heavy);
    }
  }

  p {
    padding-left: 30px;
    padding-right: 10px;
    margin-bottom: 8px;
    margin-top: 8px;
  
    color: var(--name-lite);
  }
}

/* Animation */
@-webkit-keyframes blink{
  0% { border-color: white;}
  50% { border-color: transparent;}
  100% { border-color: white;}
}
@keyframes blink{
  0% { border-color: white;}
  50% { border-color: transparent;}
  100% { border-color: white;}
}
