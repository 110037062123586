@import './styles/vars.scss';

.App {
  position: relative;

  background-color: var(--background);
  min-height: 100vh;

  font-family: 'Coustard', serif;

  .fullHeight {
    height: 100vh;
  }
}
