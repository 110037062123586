:root {
  --background: #1E1E1E;
  --name:       #DCDCAA;
  --function:   #569CD6;
  --variable:   #9CDCFE;
  --comment:    #6A9955;
  --import:     #C586C0;
  --text:       #C8C8C8;
  --symbol:     #808080;
  --string:     #CE9178;
  --keyword:    #D16969;
  --css-class:  #D7BA7D;
  --type:       #4EC9B0;
  --background-lite: #1E1E1EB0;
  --name-lite:       #DCDCAAB0;
  --function-lite:   #569CD6B0;
  --variable-lite:   #9CDCFEB0;
  --comment-lite:    #6A9955B0;
  --import-lite:     #C586C0B0;
  --text-lite:       #C8C8C8B0;
  --symbol-lite:     #808080B0;
  --string-lite:     #CE9178B0;
  --keyword-lite:    #D16969B0;
  --css-class-lite:  #D7BA7DB0;
  --type-lite:       #4EC9B080;
  --text-lite-liter: #C8C8C860;
  --background-heavy: #2E2E2E;
}

.appContainer {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}

.monospace {
  font-family: Consolas, 'Courier New', monospace;
}

.hiddenComponent {
  visibility: hidden;
}

.animated {
  &.delay-6s {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
  }
  &.delay-7s {
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
  }
  &.delay-8s {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
  }
}
